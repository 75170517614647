<template>
    <v-dialog
        ref="contentdialog"
        v-model="$store.state.dialogOpen"
        persistent
        scrollable
        max-width="800"
    >
    <v-dialog
      v-model="youtubevideo"
      max-width="300"
    >
    <v-card
      elevation="8"
    >
    <v-card-title>
      Inserisci ID youtube
    </v-card-title>
    <v-card-text>
      <v-text-field
        id="img"
        v-model="YoutubeID"
        type="text"
        required
        placeholder="ID Youtube"
        label="ID Youtube"
      />
    </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          @click="addYoutubeVideo()"
        >
          OK
        </v-btn>
        <v-btn
            color="green darken-1"
            @click="youtubevideo = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="mailto"
      max-width="300"
    >
    <v-card
      elevation="8"
    >
    <v-card-title>
      Inserisci Indirizzo email
    </v-card-title>
    <v-card-text>
      <v-text-field
        id="mailto"
        v-model="mailTo"
        type="text"
        required
        placeholder="Indirizzo email"
        label="Indirizzo email"
      />
    </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          @click="addMailTo()"
        >
          OK
        </v-btn>
        <v-btn
            color="green darken-1"
            @click="mailto = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="link"
      max-width="300"
    >
    <v-card
      elevation="8"
    >
    <v-card-title>
      Inserisci link
    </v-card-title>
    <v-card-text>
      <v-text-field
        id="link"
        v-model="Link"
        type="text"
        required
        placeholder="Link"
        label="Link"
      />
    </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          @click="addLink()"
        >
          OK
        </v-btn>
        <v-btn
            color="green darken-1"
            @click="link = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-card
      elevation="8"
    >
      <v-card-title class="headline">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-form @submit="onSubmit">
          Creato: {{ createdHuman }}
          <br>
          Modificato: {{ modifiedHuman }}
          <br>
          <br>
          <v-text-field
            id="title"
            v-model="dialogContent.title"
            :error-messages="titleErrors"
            type="text"
            required
            outlined
            placeholder="Titolo"
            label="Titolo"
            @input="$v.dialogContent.title.$touch()"
            @blur="$v.dialogContent.title.$touch()"
          />
          <v-textarea
            id="abstract"
            v-model="dialogContent.abstract"
            :error-messages="abstractErrors"
            required
            auto-grow
            outlined
            placeholder="Abstract"
            label="Abstract"
            @input="$v.dialogContent.abstract.$touch()"
            @blur="$v.dialogContent.abstract.$touch()"
          />
          <v-textarea
            id="text"
            ref="textcontent"
            v-model="dialogContent.text"
            :error-messages="textErrors"
            required
            auto-grow
            outlined
            placeholder="Testo"
            label="Testo"
            append-icon="mdi-video-box"
            @input="$v.dialogContent.text.$touch()"
            @blur="$v.dialogContent.text.$touch()"
          >
            <template
              v-slot:append
            >
              <v-tooltip right>
              <template v-slot:activator="{on}">
                <v-btn
                  icon
                  style="margin: 0px; padding: 0px"
                  @click="youtubevideo = true"
                >
                <v-icon
                  style="margin: 0px; padding: 0px"
                  v-on="on"
                >
                  mdi-video-box
                </v-icon>
                </v-btn>
              </template>
              <span>Inserisci video</span>
              </v-tooltip>
              <v-tooltip right>
              <template v-slot:activator="{on}">
                <v-btn
                  icon
                  style="margin: 0px; padding: 0px"
                  @click="link = true"
                >
                <v-icon
                  style="margin: 0px; padding: 0px"
                  v-on="on"
                >
                  mdi-link-variant
                </v-icon>
                </v-btn>
              </template>
              <span>Inserisci link </span>
              </v-tooltip>
              <v-tooltip right>
              <template v-slot:activator="{on}">
                <v-btn
                  icon
                  style="margin: 0px; padding: 0px"
                  @click="mailto = true"
                >
                <v-icon
                  style="margin: 0px; padding: 0px"
                  v-on="on"
                >
                  mdi-email
                </v-icon>
                </v-btn>
              </template>
              <span>Inserisci email</span>
              </v-tooltip>
            </template>
          </v-textarea>
          <v-card
            outlined
            class="mb-4 px-2"
            elevation="8"
            >
            <v-row>
              <v-col cols="2">
                <v-text-field
                  id="img"
                  v-model="dialogContent.img"
                  :error-messages="imageErrors"
                  type="text"
                  required
                  readonly
                  placeholder="Immagine"
                  label="Immagine"
                  @input="$v.dialogContent.img.$touch()"
                  @blur="$v.dialogContent.img.$touch()"
                  @click="$refs.imageUpload.$refs.input.click()"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                  v-if="dialogContent.image"
                  max-width="400"
                  :src="backendURL + '/images/' + dialogContent.image"
                  @click="$refs.imageUpload.$refs.input.click()"
                />
              </v-col>
              <v-col cols="4">
                <v-file-input
                  ref="imageUpload"
                  accept="image/*"
                  label="Immagine"
                  @change="uploadImage"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-card
            outlined
            class="mb-4 px-2"
            elevation="8"
            >
            <v-row>
              <v-col> Categorie </v-col>
              <v-col>
                <v-row
                  v-for="(category, i) in dialogContent.categories"
                  :key="i"
                >
                  <v-btn
                    icon
                    small
                    @click="dialogContent.categories.splice(i, 1)"
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                  {{ category }}
                </v-row>
                <br>
                <v-select
                  v-model="categorySelected"
                  :items="allCategories"
                  label="Aggiungi"
                  outlined
                  @change="addCategory"
                />
              </v-col>
            </v-row>
            </v-card>
          <v-card
            outlined
            class="mb-4 px-2"
            elevation="8"
            >
            <v-row>
              <v-col>
                Allegati
                <br>
                <v-file-input
                  ref="documentUpload"
                  label="Carica documento"
                  @change="uploadDocument"
                />
              </v-col>
              <v-col>
                <v-row
                  v-for="(document, i) in dialogContent.documents"
                  :key="i"
                >
                  <v-btn
                    icon
                    small
                    @click="dialogContent.documents.splice(i, 1)"
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                  {{ document.realfilename }}
                </v-row>
                <br>
                <v-select
                  v-model="documentSelected"
                  :items="allDocuments"
                  item-text="realfilename"
                  item-value="id"
                  label="Aggiungi documento esistente"
                  outlined
                  @change="addDocument"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-card
            outlined
            class="mb-4 px-2"
            elevation="8"
            >
            <v-row>
              <v-col cols="3">
                PhotoGallery
                <br>
              </v-col>
              <v-col cols="9">
                <v-row
                  v-for="(photogallery, i) in dialogContent.photogalleries"
                  :key="i"
                >
                  <v-btn
                    icon
                    small
                    @click="dialogContent.photogalleries.splice(i, 1)"
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                  {{ photogallery.title }}
                </v-row>
                <br>
                <v-select
                  v-model="photogallerySelected"
                  :items="allPhotogalleries"
                  item-text="title"
                  item-value="id"
                  label="Aggiungi photogallery"
                  outlined
                  @change="addPhotogallery"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-card
            outlined
            elevation="8"
            class="mb-4 px-2"
          >
            <v-card-text>Newsletter</v-card-text>
            <v-radio-group
              v-model="dialogContent.newsletter"
              row
            >
              <v-radio
                v-for="(newsletter,n) in newsletterTypes"
                :key="n"
                :label="newsletter"
                :value="newsletter"
              />
            </v-radio-group>
            <v-card-text>
              Legenda:<br>
              <strong>normale:</strong> questo contenuto sar&agrave; contenuto nella newsletter entro 15 giorni dalla sua creazione<br>
              <strong>escludi:</strong> questo contenuto non sar&agrave; contenuto nella newsletter<br>
              <strong>includi:</strong> questo contenuto sar&agrave; contenuto nella newsletter anche oltre i 15 giorni dalla sua creazione<br>
            </v-card-text>
          </v-card>
          <v-card
            outlined
            elevation="8"
            class="mb-4 px-2"
          >
          <v-radio-group
            v-model="dialogContent.type"
            row
          >
            <v-radio
              v-for="(type,n) in dateTypes"
              :key="n"
              :label="type"
              :value="type"
            />
          </v-radio-group>
          <v-sheet
            v-if="dialogContent.type == 'Range'"
            outlined
            class="mb-4 px-2"
            elevation="0"
            >
            <v-card-text> Date attivit&agrave; </v-card-text>
            <v-container>
            <v-row>
              <v-col>
                Inizio: {{ eventstartHuman }}
                <v-btn
                  icon
                  small
                  @click="eventstartShow = true"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
              <v-col>
                Fine: {{ eventendHuman }}
                <v-btn
                  icon
                  small
                  @click="eventendShow = true"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            </v-container>
            <v-row>
              <v-col>
                <v-date-picker
                  v-show="eventstartShow"
                  v-model="dialogContent.eventstart"
                  locale="it-it"
                  @input="closeeventstartDialog"
                />
              </v-col>
              <v-col>
                <v-date-picker
                  v-show="eventendShow"
                  v-model="dialogContent.eventend"
                  locale="it-it"
                  @input="closeeventendDialog"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet
            v-else-if="dialogContent.type == 'Lista'"
            outlined
            class="mb-4 px-2"
            elevation="0"
            >
            <v-card-text> Date attivit&agrave; </v-card-text>
            <v-row>
              <v-col>
                <v-row
                v-for="(date,j) in dialogContent.dates"
                :key="j"
                >
                <v-col cols="7">
                {{ date.human }}
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    small
                    @click="dialogContent.dates.splice(j, 1)"
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                  <v-dialog
                    v-model="dateDialog"
                    width="400"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Aggiungi data
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="currentDate"
                    width="400"
                    locale="it-it"
                    @input="closedateDialog"
                  />
                  </v-dialog>
              </v-col>
            </v-row>
          </v-sheet>
          </v-card>
          <v-card
            outlined
            class="mb-4 px-2"
            elevation="8"
            >
            <v-card-text> Date permanenza sul sito </v-card-text>
            <v-row>
              <v-col>
                Inizio: {{ sitestartHuman }}
                <v-btn
                  icon
                  small
                  @click="sitestartShow = true"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
              <v-col>
                Fine: {{ siteendHuman }}
                <v-btn
                  icon
                  small
                  @click="siteendShow = true"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-date-picker
                  v-show="sitestartShow"
                  v-model="dialogContent.sitestart"
                  locale="it-it"
                  @input="closesitestartDialog"
                />
              </v-col>
              <v-col>
                <v-date-picker
                  v-show="siteendShow"
                  v-model="dialogContent.siteend"
                  locale="it-it"
                  @input="closesiteendDialog"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-text-field
            id="availableplaces"
            v-model="dialogContent.availableplaces"
            :error-messages="availableplacesErrors"
            type="text"
            required
            outlined
            placeholder="Posti disponibili"
            label="Posti disponibili"
            append-icon="mdi-plus"
            prepend-inner-icon="mdi-minus"
            @click:append="
              dialogContent.availableplaces += 1;
              $v.dialogContent.availableplaces.$touch();
            "
            @click:prepend-inner="
              dialogContent.availableplaces -= 1;
              $v.dialogContent.availableplaces.$touch();
            "
            @input="$v.dialogContent.availableplaces.$touch()"
            @blur="$v.dialogContent.availableplaces.$touch()"
          />
          <v-text-field
            id="waitinglist"
            v-model="dialogContent.waitinglist"
            :error-messages="waitinglistErrors"
            type="text"
            required
            outlined
            placeholder="Lista d'attesa"
            label="Lista d'attesa"
            append-icon="mdi-plus"
            prepend-inner-icon="mdi-minus"
            @click:append="
              dialogContent.waitinglist += 1;
              $v.dialogContent.waitinglist.$touch();
            "
            @click:prepend-inner="
              dialogContent.waitinglist -= 1;
              $v.dialogContent.waitinglist.$touch();
            "
            @input="$v.dialogContent.waitinglist.$touch()"
            @blur="$v.dialogContent.waitinglist.$touch()"
          />
          <v-select
            v-model="dialogContent.state"
            :items="contentStates"
            outlined
            item-text="text"
            item-value="value"
            label="Stato"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          :disabled="buttonDisabled"
          @click="onSubmit()"
        >
          {{ dialogButtonText }}
        </v-btn>
        <v-btn
            color="green darken-1"
            @click="$store.state.dialogOpen = false"
        >
          Annulla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { validationMixin } from 'vuelidate'
  import { required, integer, minValue } from 'vuelidate/lib/validators'
  const dateFormat = require('dateformat')
  const axios = require('axios')
  const config = require('@/config')
  dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab',
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',
    ],
    monthNames: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic',
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  }
  export default {
    name: 'ContentDialog',
    mixins: [validationMixin],
    props: {
      dialogContent: { type: Object, default: null },
      dialogTitle: { type: String, default: '' },
      dialogButtonText: { type: String, default: '' },
      datetype: { type: String, default: '' },
    },
    validations: {
      dialogContent: {
        title: { required },
        abstract: { required },
        text: { required },
        availableplaces: { required, integer, minValue: minValue(0) },
        waitinglist: { required, integer, minValue: minValue(0) },
        img: { required },
      },
    },
    data: () => ({
      allCategories: ['SPORT', 'CULTURA', 'TURISMO', 'SOCIALE', 'CONVENZIONI', 'BIGLIETTERIA', 'TEATRO'],
      allDocuments: [],
      allPhotogalleries: [],
      categorySelected: null,
      documentSelected: null,
      photogallerySelected: null,
      eventstartShow: false,
      eventendShow: false,
      sitestartShow: false,
      siteendShow: false,
      dates: [],
      dateDialog: false,
      currentDate: '',
      dateType: 'Lista',
      dateTypes: ['Range', 'Lista', 'Avviso'],
      newsletterTypes: ['normale', 'escludi', 'includi'],
      backendURL: config.backendURL,
      contentStates: [
        { text: 'Attiva', value: 'active' },
        { text: 'Sospesa', value: 'suspended' },
        { text: 'Annullata', value: 'canceled' },
        { text: 'Rinviata', value: 'postponed' },
      ],
      // buttonDisabled: true,
      youtubevideo: false,
      YoutubeID: '',
      link: false,
      Link: '',
      mailto: false,
      mailTo: '',
      snack: {
        snackbar: false,
        snackbarText: '',
        snackbarColor: 'primary',
      },
    }),
    computed: {
      localdialogOpen: {
        get: function () {
          return this.$store.state.dialogOpen
        },
      },
      eventstartHuman: {
        get: function () {
          return dateFormat(new Date(this.dialogContent.eventstart), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      eventendHuman: {
        get: function () {
          return dateFormat(new Date(this.dialogContent.eventend), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      sitestartHuman: {
        get: function () {
          return dateFormat(new Date(this.dialogContent.sitestart), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      siteendHuman: {
        get: function () {
          return dateFormat(new Date(this.dialogContent.siteend), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      createdHuman: {
        get: function () {
          return dateFormat(new Date(this.dialogContent.created), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      modifiedHuman: {
        get: function () {
          return dateFormat(new Date(this.dialogContent.modified), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      titleErrors: {
        get: function () {
          const errors = []
          if (!this.$v.dialogContent.title.$dirty) return errors
          !this.$v.dialogContent.title.required && errors.push('Titolo necessario')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      abstractErrors: {
        get: function () {
          const errors = []
          if (!this.$v.dialogContent.abstract.$dirty) return errors
          !this.$v.dialogContent.abstract.required && errors.push('Abstract necessario')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      textErrors: {
        get: function () {
          const errors = []
          if (!this.$v.dialogContent.text.$dirty) return errors
          !this.$v.dialogContent.text.required && errors.push('Testo necessario')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      imageErrors: {
        get: function () {
          const errors = []
          if (!this.$v.dialogContent.img.$dirty) return errors
          !this.dialogContent.image && errors.push('Immagine necessaria')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      availableplacesErrors: {
        get: function () {
          const errors = []
          if (!this.$v.dialogContent.availableplaces.$dirty) return errors
          !this.$v.dialogContent.availableplaces.required && errors.push('Posti disponibili necessari')
          !this.$v.dialogContent.availableplaces.integer && errors.push('Solo valori numerici')
          !this.$v.dialogContent.availableplaces.minValue && errors.push('Valore negativo')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      waitinglistErrors: {
        get: function () {
          const errors = []
          if (!this.$v.dialogContent.waitinglist.$dirty) return errors
          !this.$v.dialogContent.waitinglist.required && errors.push('Lista d\'attesa necessaria')
          !this.$v.dialogContent.waitinglist.integer && errors.push('Solo valori numerici')
          !this.$v.dialogContent.waitinglist.minValue && errors.push('Valore negativo')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
    },
    watch: {
      localdialogOpen: function (val) {
        this.checkDialogErrors()
      },
      datetype: function (val) {
        this.dateType = val
      },
    },
    created: function () {
      var component = this
      var currentJWT = localStorage.getItem(config.authToken)
      this.checkDialogErrors()
      axios
        .get(config.backendURL + '/documents', {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          localStorage.setItem(config.authToken, response.data.jwt)
          component.allDocuments = response.data.documents
        })
        .catch(function (response) {
          console.log(response)
        })
      axios
        .get(config.backendURL + '/adminphotogalleries', {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          localStorage.setItem(config.authToken, response.data.jwt)
          component.allPhotogalleries = response.data.photogalleries
        })
        .catch(function (response) {
          console.log(response)
        })
      axios
        .get(config.backendURL + '/categories', {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          if (response.data.jwt) {
            localStorage.setItem(config.authToken, response.data.jwt)
          }
          component.allCategories = []
          response.data.categories.forEach(category => {
            component.allCategories.push(category.name)
          })
        })
        .catch(function (response) {
          console.log(response)
        })
    },

    methods: {
      addCategory (cat) {
        var component = this
        if (!component.dialogContent.categories.includes(cat)) {
          component.dialogContent.categories.push(cat)
        }
        component.categorySelected = null
      },
      addDocument (doc) {
        var component = this
        for (var currentdocument of this.allDocuments) {
          if (currentdocument.id === doc) {
            if (!component.dialogContent.documents.includes(currentdocument)) {
              component.dialogContent.documents.push(currentdocument)
            }
          }
        }
        component.documentSelected = null
      },
      addPhotogallery (pg) {
        var component = this
        var already = false
        for (var currentphotogallery of component.allPhotogalleries) {
          if (currentphotogallery.id === pg) {
            for (var cur of component.dialogContent.photogalleries) {
              if (cur.id === pg) {
                already = true
              }
            }
            if (!already) {
              component.dialogContent.photogalleries.push(currentphotogallery)
            }
          }
        }

        component.photogallerySelected = null
      },
      checkDialogErrors () {
        if (
          this.$v.dialogContent.title.required &&
          this.$v.dialogContent.abstract.required &&
          this.$v.dialogContent.text.required &&
          this.$v.dialogContent.availableplaces.required &&
          this.$v.dialogContent.availableplaces.minValue &&
          this.$v.dialogContent.availableplaces.integer &&
          this.$v.dialogContent.waitinglist.required &&
          this.$v.dialogContent.waitinglist.minValue &&
          this.$v.dialogContent.waitinglist.integer &&
          this.dialogContent.image
        ) {
          this.buttonDisabled = false
        } else {
          this.buttonDisabled = true
        }
      },
      closedateDialog () {
        var component = this
        this.dateDialog = false
        component.dialogContent.dates.push({ date: component.currentDate, human: dateFormat(new Date(component.currentDate), 'd mmmm yyyy') })
      },
      closeeventstartDialog () {
        this.eventstartShow = false
      },
      closeeventendDialog () {
        this.eventendShow = false
      },
      closesitestartDialog () {
        this.sitestartShow = false
      },
      closesiteendDialog () {
        this.siteendShow = false
      },
      uploadImage (file) {
        var component = this
        var formData = new FormData()
        formData.append('file', file)
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .post(config.backendURL + '/images', formData, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.dialogContent.image = response.data.imageid
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      uploadDocument (file) {
        if (file === undefined) {
          return
        }
        var component = this
        var formData = new FormData()
        formData.append('file', file)
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .post(config.backendURL + '/documents', formData, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.dialogContent.documents.push(response.data.document)
              axios
                .get(config.backendURL + '/documents', {
                  headers: {
                    authorization: 'Bearer ' + currentJWT,
                  },
                })
                .then(function (response) {
                  component.dialogContent.allDocuments = response.data.documents
                })
                .catch(function (response) {
                  console.log(response)
                })
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      getContents () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        // var payload = jwt.decode(currentJWT)
        axios
          .get(config.backendURL + '/admincontents', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.contents = response.data.contents
            component.contents.forEach(content => {
              var index = content.categories.indexOf('NOVITÀ')
              if (index > -1) {
                content.categories.splice(index, 1)
              }
            })
            component.dataMissing = false
            localStorage.setItem(config.authToken, response.data.jwt)
          })
          .catch(function (response) {
            console.log(response)
          })
        component.$emit('customevent', this.dialogContent)
      },
      addYoutubeVideo () {
        if (!this.YoutubeID.length) {
          return
        }
        var selStart = this.$refs.textcontent.$refs.input.selectionStart
        var selEnd = this.$refs.textcontent.$refs.input.selectionEnd
        var text = this.$refs.textcontent.$refs.input.value
        var startText = text.substr(0, selStart)
        var endText = text.substr(selEnd)
        this.dialogContent.text = startText + ':::video:' + this.YoutubeID + ':::' + endText
        this.youtubevideo = false
        this.$refs.textcontent.$refs.input.selectionStart = selStart + 12 + this.YoutubeID.length
        this.$refs.textcontent.$refs.input.selectionEnd = selEnd + 12 + this.YoutubeID.length
        this.YoutubeID = ''
      },
      addLink () {
        if (!this.Link.length) {
          return
        }
        var selStart = this.$refs.textcontent.$refs.input.selectionStart
        var selEnd = this.$refs.textcontent.$refs.input.selectionEnd
        var text = this.$refs.textcontent.$refs.input.value
        var startText = text.substr(0, selStart)
        var endText = text.substr(selEnd)
        var tolink = text.substr(selStart, selEnd-selStart)
        this.dialogContent.text = startText + '<a href="' + this.Link + '">' + tolink + '</a>' + endText
        this.link = false
        this.$refs.textcontent.$refs.input.selectionStart = selStart + 11 + this.Link.length
        this.$refs.textcontent.$refs.input.selectionEnd = selEnd + 11 + this.Link.length
        this.Link = ''
      },
      addMailTo () {
        if (!this.mailTo.length) {
          return
        }
        var selStart = this.$refs.textcontent.$refs.input.selectionStart
        var selEnd = this.$refs.textcontent.$refs.input.selectionEnd
        var text = this.$refs.textcontent.$refs.input.value
        var startText = text.substr(0, selStart)
        var endText = text.substr(selEnd)
        var tolink = text.substr(selStart, selEnd-selStart)
        this.dialogContent.text = startText + '<a href="mailto:' + this.mailTo + '">' + tolink + '</a>' + endText
        this.mailto = false
        this.$refs.textcontent.$refs.input.selectionStart = selStart + 13 + this.Link.length
        this.$refs.textcontent.$refs.input.selectionEnd = selEnd + 13 + this.Link.length
        this.mailTo = ''
      },
      onSubmit () {
        var component = this
        var tosubmit = component.dialogContent
        tosubmit.allDocuments = []
        tosubmit.datetype = component.dateType
        var currentJWT = localStorage.getItem(config.authToken)
        if (component.dialogContent.id) {
          axios
            .put(config.backendURL + '/contents', tosubmit, {
              headers: {
                authorization: 'Bearer ' + currentJWT,
              },
            })
            .then(function (response) {
              if (response.data.httpStatus === 200) {
                component.snack.snackbarText = 'Contenuto salvato correttamente'
                component.snack.snackbarColor = 'primary'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
                component.getContents()
              } else {
                component.snack.snackbarText = response.data.longMessage
                component.snack.snackbarColor = 'error'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
              }
            })
            .catch(function (response) {
              console.log(response)
            })
        } else {
          axios
            .post(config.backendURL + '/contents', component.dialogContent, {
              headers: {
                authorization: 'Bearer ' + currentJWT,
              },
            })
            .then(function (response) {
              if (response.data.httpStatus === 200) {
                localStorage.setItem(config.authToken, response.data.jwt)
                component.snack.snackbarText = 'Contenuto creato correttamente'
                component.snack.snackbarColor = 'primary'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
                component.getContents()
              } else {
                component.snack.snackbarText = response.data.longMessage
                component.snack.snackbarColor = 'error'
                component.snack.snackbar = true
                component.$emit('update-snack', component.snack)
              }
            })
            .catch(function (response) {
              console.log(response)
            })
        }
        this.$store.state.dialogOpen = false
      },
    },
  }
</script>
<style >
.v-btn--icon.v-size--default {
  width: 16px;
  height: 16px;
}

</style>
