<template>
  <v-container
    id="contents"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snack.snackbar"
      timeout="3000"
      :color="snack.snackbarColor"
    >
    {{ snack.snackbarText }}
    </v-snackbar>

    <v-row
      justify="center"
    >
      <v-spacer />
      <v-col cols="2">
      <v-btn
        dark
        @click="addContent()"
        >
        Nuovo
        </v-btn>
        <v-dialog
          v-model="deleteConfirm"
          persistent
          scrollable
          max-width="400"
        >
          <v-card>
            <v-card-title>
              Conferma cancellazione
            </v-card-title>
            <v-card-text
              v-html="deleteText"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                @click="deleteContentExec"
              >
                OK
              </v-btn>
              <v-btn
                color="green darken-1"
                @click="deleteConfirm = false"
              >
                Annulla
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="cloneConfirm"
          persistent
          scrollable
          max-width="400"
        >
          <v-card>
            <v-card-title>
              Conferma clonazione
            </v-card-title>
            <v-card-text
              v-html="cloneText"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                @click="cloneContentExec"
              >
                OK
              </v-btn>
              <v-btn
                color="green darken-1"
                @click="cloneConfirm = false"
              >
                Annulla
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ContentDialog
          :dialogContent="dialogContent"
          :dialogTitle="dialogTitle"
          :datetype="datetype"
          :dialogButtonText="dialogButtonText"
          @customevent="getContents()"
          @update-snack="updateSnack"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row justify="center">
      <v-spacer />
      <v-col
        :cols="$isMobile()? 10 : 3"
      >
        <v-text-field
          v-model="searchValue"
          label="Cerca..."
          color="primary"
          hide-details
          style="max-width: 165px;"
        >
          <!--template
            v-if="$vuetify.breakpoint.mdAndUp"
            v-slot:append-outer
          >
            <v-btn
              class="mt-n2"
              elevation="1"
              fab
              small
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template-->
        </v-text-field>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      v-show="dataMissing"
      justify="center"
    >
      <v-col
        cols="2"
      >
        <br>
        <br>
        <br>
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
        />
      </v-col>
    </v-row>
    <container
      v-show="!dataMissing"
      lock-axis="y"
      drag-class="card-ghost"
      drop-class="card-ghost-drop"
      @drop="onDrop"
    >
      <draggable
        v-for="(content,i) in contents"
        :key="i"
        align="center"
      >
        <v-card
          v-if="checkSearch(content.title, content.abstract)"
          class="draggable-item mb-2 mt-1"
          elevation="10"
          max-height="80"
          outlined
          max-width="1000"
          :color="checkContentValidity(i) ? 'accent' : checkNotice(i) ? 'info' : undefined"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                class="text-subtitle-2"
              >
                <v-icon
                  v-if="content.newsletter === 'includi'"
                  small
                >
                  mdi-email-check-outline
                </v-icon>
                <v-icon
                  v-else-if="content.newsletter === 'escludi'"
                  small
                >
                  mdi-email-off-outline
                </v-icon>
                {{ content.title }}
              </v-col>
              <v-col cols="1">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      :src="backendURL + '/images/' + content.image"
                      max-height="40"
                      max-width="70"
                      contain
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-chip
                        v-if="content.state !== 'active'"
                        color="error"
                        x-small
                      >
                        {{ content.state }}
                      </v-chip>
                    </v-img>
                  </template>
                  <v-img
                    :src="backendURL + '/images/' + content.image"
                    width="400"
                    contain
                    v-bind="attrs"
                    v-on="on"
                  />
                </v-tooltip>
              </v-col>
              <v-col cols="3">
                <v-chip
                  v-for="(category,catindex) in content.categories"
                  :key="catindex"
                  x-small
                >
                  {{ category }}
                </v-chip>
              </v-col>
              <v-spacer />
              <v-col
                cols="2"
                class="text-right"
              >
                <v-tooltip
                  v-if="i > 0"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :small="!$isMobile()"
                      :x-small="$isMobile()"
                      v-bind="attrs"
                      v-on="on"
                      @click="onDrop({ removedIndex: i, addedIndex: 0, payload: undefined })"
                    >
                      <v-icon small>
                        mdi-arrow-up-bold
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Sposta in testa</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :small="!$isMobile()"
                      :x-small="$isMobile()"
                      v-bind="attrs"
                      v-on="on"
                      @click="editContent(i)"
                    >
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :small="!$isMobile()"
                      :x-small="$isMobile()"
                      v-bind="attrs"
                      v-on="on"
                      @click="cloneContent(i)"
                    >
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Clona</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteContent(i)"
                    >
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </draggable>
    </container>
  </v-container>
</template>
<script>
  import { Container, Draggable } from 'vue-smooth-dnd'
  // import { component } from 'vue/types/umd'
  import ContentDialog from './ContentDialog.vue'
  const config = require('@/config')
  const axios = require('axios')
  const dateFormat = require('dateformat')
  dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab',
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',
    ],
    monthNames: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic',
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  }
  // const jwt = require('jsonwebtoken')
  export default {
    name: 'Contents',
    components: { Container, Draggable, ContentDialog },
    data: () => ({
      searchValue: '',
      contents: [],
      title: '',
      dialogTitle: 'Nuovo contenuto',
      dialogButtonText: 'Aggiungi',
      datetype: 'Lista',
      backendURL: config.backendURL,
      attrs: null,
      on: null,
      dialogContent: null,
      emptyContent: {
        id: null,
        title: '',
        abstract: '',
        text: '',
        image: null,
        img: '',
        eventstart: null,
        eventend: null,
        sitestart: new Date().toISOString().substring(0, 10).toString(),
        siteend: new Date().toISOString().substring(0, 10).toString(),
        notice: false,
        availableplaces: 0,
        waitinglist: 0,
        state: 'active',
        listorder: 1,
        categories: [],
        documents: [],
        photogalleries: [],
        dateType: 'Lista',
        dates: [],
        created: new Date().toISOString().substring(0, 10).toString(),
        modified: new Date().toISOString().substring(0, 10).toString(),
      },
      deleteConfirm: false,
      deleteText: '',
      deleteId: null,
      cloneConfirm: false,
      cloneText: '',
      cloneId: null,
      dataMissing: true,
      snack: {
        snackbar: false,
        snackbarText: '',
        snackbarColor: 'primary',
      },
    }),
    created: function () {
      this.getContents()
      this.dialogContent = this.emptyContent
    },
    methods: {
      updateSnack (snack) {
        this.snack = snack
      },
      checkSearch (title, abstract) {
        if (this.searchValue === '') {
          return true
        }
        if (title.toLowerCase().search(this.searchValue.toLowerCase()) >= 0 || abstract.toLowerCase().search(this.searchValue.toLowerCase()) >= 0) {
          return true
        } else {
          return false
        }
      },
      checkDialogErrors () {
        var component = this
        if (
          this.$v.dialogContent.title.required &&
          this.$v.dialogContent.abstract.required &&
          this.$v.dialogContent.text.required &&
          this.$v.dialogContent.availableplaces.required &&
          this.$v.dialogContent.availableplaces.minValue &&
          this.$v.dialogContent.availableplaces.integer &&
          this.$v.dialogContent.waitinglist.required &&
          this.$v.dialogContent.waitinglist.minValue &&
          this.$v.dialogContent.waitinglist.integer &&
          this.dialogContent.image
        ) {
          component.buttonDisabled = false
        } else {
          component.buttonDisabled = true
        }
      },
      clearDialogContent () {
        var component = this
        component.dialogContent = {
          id: null,
          title: '',
          abstract: '',
          text: '',
          image: null,
          img: '',
          eventstart: new Date().toISOString().substring(0, 10).toString(),
          eventend: new Date().toISOString().substring(0, 10).toString(),
          sitestart: new Date().toISOString().substring(0, 10).toString(),
          siteend: new Date().toISOString().substring(0, 10).toString(),
          created: new Date().toISOString().substring(0, 10).toString(),
          modified: new Date().toISOString().substring(0, 10).toString(),
          notice: false,
          availableplaces: 0,
          waitinglist: 0,
          state: 'active',
          newsletter: 'normale',
          listorder: 1,
          categories: [],
          documents: [],
          dates: [],
          photogalleries: [],
          dateype: 'Lista',
          type: 'Lista',
        }
        // this.$v.dialogContent.img.$touch()
      },
      editContent (id) {
        this.dialogContent = this.contents[id]
        this.contents[id].dates.forEach(element => {
          element.human = dateFormat(new Date(element.date), 'd mmmm yyyy')
        })
        if (this.dialogContent.type !== 'Range') {
          this.dialogContent.eventstart = new Date().toISOString().substring(0, 10).toString()
          this.dialogContent.eventend = new Date().toISOString().substring(0, 10).toString()
        }
        // TODO: riempire con quello che arriva
        this.dialogContent.img = 'presente'
        this.eventstartHuman = dateFormat(new Date(this.dialogContent.eventstart), 'd mmmm yyyy')
        this.eventendHuman = dateFormat(new Date(this.dialogContent.eventend), 'd mmmm yyyy')
        this.sitestartHuman = dateFormat(new Date(this.dialogContent.sitestart), 'd mmmm yyyy')
        this.siteendHuman = dateFormat(new Date(this.dialogContent.siteend), 'd mmmm yyyy')
        this.dialogTitle = 'Modifica contenuto'
        this.datetype = this.contents[id].datetype
        this.dialogButtonText = 'Salva'
        this.dialogContent.datetype = this.contents[id].datetype
        // console.log(this.contents[id].datetype)
        // this.checkDialogErrors()
        // this.buttonDisabled = false
        this.$store.state.dialogOpen = true
      },
      deleteContent (id) {
        var component = this
        this.dialogContent = this.contents[id]
        component.deleteConfirm = true
        component.deleteText = 'Sei sicuro di voler rimuovere il contenuto &laquo;' + this.dialogContent.title + '&raquo;'
        component.deleteId = this.dialogContent.id
        component.deleteConfirm = true
      },
      cloneContent (id) {
        var component = this
        this.dialogContent = this.contents[id]
        component.cloneConfirm = true
        component.cloneText = 'Sei sicuro di voler clonare il contenuto &laquo;' + this.dialogContent.title + '&raquo;'
        component.cloneId = this.dialogContent.id
        component.cloneConfirm = true
      },
      checkContentValidity (index) {
        var now = new Date().toISOString().substring(0, 10).toString()
        if (this.contents[index].sitestart <= now && this.contents[index].siteend >= now) {
          return false
        } else {
          return true
        }
      },
      checkNotice (index) {
        if (this.contents[index].type === 'Avviso') {
          return true
        } else {
          return false
        }
      },
      addContent () {
        this.clearDialogContent()
        this.dialogTitle = 'Nuovo contenuto'
        this.dialogButtonText = 'Aggiungi'
        this.dialogContent.datetype = 'Lista'
        this.datetype = 'Lista'
        // this.checkDialogErrors()
        this.$store.state.dialogOpen = true
      },
      deleteContentExec () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .delete(config.backendURL + '/contents/' + component.deleteId, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.deleteConfirm = false
            if (response.data.httpStatus === 200) {
              component.getContents()
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snack.snackbarText = 'Contenuto rimosso correttamente'
              component.snack.snackbarColor = 'primary'
              component.snack.snackbar = true
            } else {
              component.snack.snackbarText = response.data.longMessage
              component.snack.snackbarColor = 'error'
              component.snack.snackbar = true
            }
          })
          .catch(function (response) {
            console.log(response)
            component.deleteConfirm = false
          })
      },
      cloneContentExec () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios.defaults.headers.post.authorization = 'Bearer ' + currentJWT
        axios
          .post(config.backendURL + '/contents/clone/' + component.cloneId, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.cloneConfirm = false
            if (response.data.httpStatus === 200) {
              component.getContents()
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snack.snackbarText = 'Contenuto clonato correttamente'
              component.snack.snackbarColor = 'primary'
              component.snack.snackbar = true
            } else {
              component.snack.snackbarText = response.data.longMessage
              component.snack.snackbarColor = 'error'
              component.snack.snackbar = true
            }
          })
          .catch(function (response) {
            console.log(response)
            component.cloneConfirm = false
          })
      },
      dragStart () {
        return false
      },
      onDrop (dropResult) {
        const { removedIndex, addedIndex, payload } = dropResult
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        if (dropResult.removedIndex !== dropResult.addedIndex) {
          axios.defaults.headers.put.authorization = 'Bearer ' + currentJWT
          axios
            .put(config.backendURL + '/contents/reorder/' + component.contents[dropResult.removedIndex].id + '/' + component.contents[dropResult.addedIndex].id, {
              headers: {
                authorization: 'Bearer ' + currentJWT,
              },
            })
            .then(function (response) {
              if (response.data.httpStatus === 200) {
                component.getContents()
                localStorage.setItem(config.authToken, response.data.jwt)
              } else {
                component.snack.snackbarText = response.data.longMessage
                component.snack.snackbarColor = 'error'
                component.snack.snackbar = true
              }
            })
            .catch(function (response) {
              console.log(response)
            })
        }
        if (dropResult.removedIndex !== dropResult.addedIndex) {
          const result = [...component.contents]
          let itemToAdd = payload
          if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0]
          }
          if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd)
          }
          component.contents = result
        }
      },
      getContents () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        // var payload = jwt.decode(currentJWT)
        component.dataMissing = true
        axios
          .get(config.backendURL + '/admincontents', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.contents = response.data.contents
            component.contents.forEach(content => {
              var index = content.categories.indexOf('NOVITÀ')
              if (index > -1) {
                content.categories.splice(index, 1)
              }
            })
            localStorage.setItem(config.authToken, response.data.jwt)
            component.dataMissing = false
          })
          .catch(function (response) {
            component.dataMissing = false
            console.log(response)
          })
      },
    },
  }
</script>
<style>
  .v-input__slot.theme--light.v-label {
    color: #333333;
  }
  .v-input__slot .v-label{
    color: black!important
  }
</style>
